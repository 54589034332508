<template>
  <div class="contents">
    <div class="title">
      <h1 v-html="$t('photo.title')"></h1>
      <p>{{ $t('photo.subtitle') }}</p>
    </div>
    <div class="smenu">
      <div class="mypage_sub_title">
        <a href="javascript:" @click="onBack">
          <img src="../../assets/img/btn_back.png">
        </a>
      </div>
      <UserImage v-if="userInfo != null" :gender="userInfo.user_sex" :image="userInfo.user_profile_image"
        class="profile_image" />

      <div v-if="userInfo != null" class="nickname_gender_age mobile">
        <span class="nickname">
          {{ userInfo?.user_nickname }}
          <span class="gender_age" :class="[{ male: userInfo.user_sex == 0, female: userInfo.user_sex == 1 }]">
            ({{
        (userInfo.user_sex == 1
          ? $t('common.female_short') : $t('common.male_short')) + userInfo.user_age }})
          </span>
        </span>
      </div>

      <div v-if="userInfo != null">
        <div>
          <div class="nickname_gender_age pc">
            <span class="nickname">
              {{ userInfo?.user_nickname }}
            </span>
            <div class="gender_age" :class="[{ male: userInfo.user_sex == 0, female: userInfo.user_sex == 1 }]">
              ({{
        (userInfo.user_sex == 1
          ? $t('common.female_short') : $t('common.male_short')) + userInfo.user_age }})
            </div>
          </div>
          <div v-if="myInfo != null && userInfo.uid == myInfo.uid" class="my_actions">
            <a href="javascript:" @click="onProfileEdit()">{{ $t('album.profile_edit') }}</a>
            <a href="javascript:" @click="onWrite()">
              {{ $t('album.write') }}
            </a>
          </div>
          <div v-if="myInfo == null || userInfo.uid != myInfo.uid" class="other_actions">
            <a href="javascript:" @click="onVideoCall()">{{ $t('album.video_call') }}</a>
            <a href="javascript:" @click="onSendMessage()">
              {{ $t('album.send_message') }}
            </a>
            <a v-if="socialInfo.my_following_status == 0" href="javascript:" @click="onFollow()">
              {{ $t('album.follow') }}
            </a>
            <a v-if="socialInfo.my_following_status == 1" href="javascript:" @click="onUnfollow()">
              {{ $t('album.unfollow') }}
            </a>
          </div>
        </div>

        <div class="social_info">
          <div class="social_info_item">
            <span>{{ socialInfo.zzalbes }}</span>
            <span>{{ $t('album.posts') }}</span>
          </div>

          <div class="social_info_item">
            <span>{{ socialInfo.likes }}</span>
            <span>{{ $t('album.likes') }}</span>
          </div>

          <div class="social_info_item">
            <span>{{ socialInfo.followers }}</span>
            <span>{{ $t('album.followers') }}</span>
          </div>

          <div class="social_info_item">
            <span>{{ socialInfo.followings }}</span>
            <span>{{ $t('album.followings') }}</span>
          </div>
        </div>

        <div v-if="myInfo != null && userInfo.uid == myInfo.uid" class="motto_input">
          <div class="motto_input_field">
            <input type="text" v-model="socialMotto" :placeholder="$t('album.motto_hint')" />
          </div>
          <a href="javascript:" @click="onSaveSocialMotto()">{{ $t('album.save') }}</a>
        </div>
        <div v-if="myInfo == null || userInfo.uid != myInfo.uid" class="other_motto">
          {{ userInfo.user_social_motto }}
        </div>
      </div>

    </div>

    <div class="tab_bar">
      <a class="item" :class="{ selected: tabIndex == 0 }" href="javascript:" @click="onTab(0)">
        <img
          :src="require(tabIndex == 0 ? '../../assets/img/album_tab_gallery_on.png' : '../../assets/img/album_tab_gallery_off.png')">
      </a>
      <a class="item" :class="{ selected: tabIndex == 1 }" href="javascript:" @click="onTab(1)">
        <img
          :src="require(tabIndex == 1 ? '../../assets/img/album_tab_follow_on.png' : '../../assets/img/album_tab_follow_off.png')">
      </a>
    </div>

    <div class="warp">
      <div v-if="tabIndex == 0 || followed == 1 || (myInfo != null && userInfo.uid == myInfo.uid)" class="moment_list">
        <ul>
          <li v-for="(photo, idx) in photoList" :key="idx">
            <a href="javascript:" @click="onCell(idx)">
              <div>
                <img v-if="photo.image != ''" v-lazy="photo.image">
                <div class="story_item_public_flag_tag">
                  <story-item-public-flag-tag-view :isMyMoment="myInfo != null && myInfo.uid == userInfo.uid"
                    :publicFlag="photo.public_flag" :price="photo.price" :isFollowing="photo.is_following"
                    :isPurchased="photo.is_purchased" @onBuy="onBuy(idx)" />
                </div>
              </div>

              <img v-if="photo.media_type == 0 && photo.image.split('###').length > 1"
                src="../../assets/img/story_item_type_images.png" class="media_type_tag">
              <img v-if="photo.media_type == 1" src="../../assets/img/story_item_type_video.png" class="media_type_tag">
              <img v-if="photo.media_type == 2" src="../../assets/img/story_item_type_audio.png" class="media_type_tag">
              <h2>
                <img style="width: 18px; height: 15px; margin-top: -5px;"
                  :src="require('../../assets/flag/' + photo.user_country_code + '.png')">
                {{ photo.user_nickname }}
                <span :class="[{ male: photo.user_sex == '0', female: photo.user_sex == '1' }]">
                  ({{
        (photo.user_sex == '0' ? $t('common.male_short') : $t('common.female_short')) + photo.user_age
      }})
                </span>
              </h2>
              <p>
                <span><img src="../../assets/img/ico_view.png">{{ currency(photo.hits) }}</span>
                <span><img src="../../assets/img/ico_recommend.png">{{ currency(photo.recommends) }}</span>
              </p>
            </a>
          </li>
        </ul>
        <div v-if="photoList.length == 0" class="no_photos">
          <img src="../../assets/img/album_follow_empty.png">
          <div>{{ $t('album.no_photos') }}</div>
        </div>
      </div>
      <div v-else class="must_follow_each_other">
        <img src="../../assets/img/album_follow_empty.png">
        <div>{{ $t('album.must_follow_each_other_text1') }}</div>
        <div>{{ $t('album.must_follow_each_other_text2') }}</div>
      </div>
      <div class="clear"></div>
    </div>
  </div>

  <CreateMomentStep1 :show="showCreateMomentStep1" @close="showCreateMomentStep1 = false"
    @next="onCreateMomentStep1Next" />

  <CreateMomentStep2 :show="showCreateMomentStep2" :images="createMomentStep1Images"
    :imageDatas="createMomentStep1ImageDatas" @close="showCreateMomentStep2 = false" @done="onCreateMomentStep2Done" />

  <MomentDetail :show="showMomentDetail" :data="photoList" :index="selectMomentIdx" @close="showMomentDetail = false" />

  <StoryPurchaseConfirm
      :show="showStoryPurchaseConfirmModal"
      :photoInfo="photoInfo"
      @close="showStoryPurchaseConfirmModal = false"
      @ok="onStoryPurchaseOk"/>
</template>

<style lang="scss" scoped src="./Album.scss"></style>
<style lang="scss" scoped src="./Moment.scss"></style>

<script>
import { EVENT_NAME, PAGE_SIZE } from "../../lib/constant";
import { requestPost, showToast } from "@/lib/util";
import InfiniteLoading from 'v3-infinite-loading';
import CreateMomentStep1 from "@/views/moment/create_moment/CreateMomentStep1";
import CreateMomentStep2 from "@/views/moment/create_moment/CreateMomentStep2";
import MomentDetail from "@/views/moment/MomentDetail";
import StoryPurchaseConfirm from "@/views/moment/StoryPurchaseConfirm";
import UserImage from "../../components/UserImage";
import StoryItemPublicFlagTagView from "../../components/StoryItemPublicFlagTagView.vue";

export default {
  name: "Album",
  components: {
    InfiniteLoading,
    CreateMomentStep1,
    CreateMomentStep2,
    MomentDetail,
    StoryPurchaseConfirm,
    UserImage,
    StoryItemPublicFlagTagView,
  },
  data() {
    let myInfo = this.$store.getters.me;

    return {
      myInfo: myInfo,
      userInfo: null,
      photoList: [],
      loadingEnd: false,
      socialInfo: {
        zzalbes: 0,
        likes: 0,
        followers: 0,
        followings: 0,
        social_motto: '',
        my_following_status: 0,
      },
      socialMotto: '',
      followed: 0,

      showCreateMomentStep1: false,
      showCreateMomentStep2: false,
      createMomentStep1Images: null,
      createMomentStep1ImageDatas: null,

      tabIndex: 0,

      selectMomentIdx: 0,
      photoInfo: {},
      showMomentDetail: false,

      showStoryPurchaseConfirmModal: false,
    }
  },
  mounted() {console.log('myInfo:', this.myInfo);
    this.photoList = [];
    this.loadingEnd = false;
    let storedUserInfoParam = window.localStorage.getItem('Album.UserInfo');
    if (storedUserInfoParam == null) {
      this.$router.back();
      return;
    }
    this.userInfo = JSON.parse(storedUserInfoParam);
    this.getUserSocialInfo();
    this.getList();
    this.emitter.on(EVENT_NAME.REPORT_SUCCESS, this.initList);
  },
  beforeUnmount() {
    this.emitter.off(EVENT_NAME.REPORT_SUCCESS, this.initList);
  },
  watch: {
    '$route.params.uid'(value) {
      this.photoList = [];
      this.loadingEnd = false;
      let storedUserInfoParam = window.localStorage.getItem('Album.UserInfo');
      if (storedUserInfoParam == null) {
        this.$router.back();
        return;
      }
      this.userInfo = JSON.parse(storedUserInfoParam);
      this.getUserSocialInfo();
    }
  },
  methods: {
    getImageThumbnailUri: function (imageUrl) {
      if (imageUrl.startsWith('file://')) {
        return imageUrl;
      }

      return imageUrl ? imageUrl.replace('/image_', '/s_image_') : '';
    },
    initList: function () {
      this.photoList = [];
      this.loadingEnd = false;

      this.getList();
    },
    getList: function ($state) {
      let _app = this;

      if (this.loadingEnd) {
        return;
      }

      requestPost(
        this.myInfo == null ? 'zzalbe/getZzalbesWithoutLogin' : 'zzalbe/getZzalbes',
        {
          country_code: '',
          category: 0,
          uid: this.userInfo.uid,
          type: 'me',
          sub_type: this.tabIndex == 0 ? 'public' : 'protected',
          latitude: 0,
          longitude: 0,
          offset: this.photoList.length,
          limit: PAGE_SIZE
        },
        response => {
          _app.photoList = _app.photoList.concat(response.zzalbes);
          _app.loadingEnd = response.zzalbes.length < PAGE_SIZE;
          if ($state) {
            if (_app.loadingEnd) {
              $state.complete();
            }
            $state.loaded();
          }
        },
        (code, msg) => {
          showToast(msg);
          if ($state) {
            $state.loaded();
          }
        }
      );
    },
    onBack: function () {
      this.$router.back();
    },
    onVideoCall: function () {
      if (this.myInfo == null) {
        this.$router.replace({
          name: 'Logout'
        });
        return;
      }

      this.$root.showUserDlg(this.userInfo);
    },
    onSendMessage: function () {
      if (this.myInfo == null) {
        this.$router.replace({
          name: 'Logout'
        });
        return;
      }

      this.$root.showMessageSendDlg(this.userInfo);
    },
    onFollow: function () {
      if (this.myInfo == null) {
        this.$router.replace({
          name: 'Logout'
        });
        return;
      }

      let _app = this;
      requestPost(
        'jjim/createJjim',
        {
          uid: this.myInfo.uid,
          peer_uid: this.userInfo.uid
        },
        () => {
          _app.followed = 1;
          _app.getUserSocialInfo();
          _app.initList();
        },
        (code, msg) => {
          showToast(msg);
        }
      );
    },
    onUnfollow: function () {
      if (this.myInfo == null) {
        this.$router.replace({
          name: 'Logout'
        });
        return;
      }

      let _app = this;
      requestPost(
        'jjim/deleteJjim',
        {
          uid: this.myInfo.uid,
          peer_uid: this.userInfo.uid
        },
        () => {
          _app.followed = 0;
          _app.getUserSocialInfo();
          _app.initList();
        },
        (code, msg) => {
          showToast(msg);
        }
      );
    },
    onProfileEdit: function () {
      this.$router.push('/profile-edit');
    },
    onWrite: function () {
      this.showCreateMomentStep1 = true;
    },
    onCreateMomentStep1Next: function (images, imageDatas) {
      this.showCreateMomentStep1 = false;
      this.createMomentStep1Images = images;
      this.createMomentStep1ImageDatas = imageDatas;
      this.showCreateMomentStep2 = true;
    },
    onCreateMomentStep2Done: function () {
      this.showCreateMomentStep2 = false;
    },
    onSaveSocialMotto: function () {
      if (this.socialMotto == '') {
        showToast(this.$t('album.input_motto'));
        return;
      }

      let _app = this;
      requestPost(
        'user/setSocialMotto',
        {
          uid: this.myInfo.uid,
          social_motto: this.socialMotto
        },
        () => {
        },
        (code, msg) => {
          showToast(msg);
        }
      );
    },
    getUserSocialInfo: function () {
      let _app = this;

      requestPost(
        this.myInfo == null ? 'user/getSocialInfoWithoutLogin' : 'user/getSocialInfo',
        {
          peer_uid: this.userInfo.uid,
        },
        response => {
          _app.socialInfo = response;
          _app.socialMotto = response.social_motto ?? '';
          _app.followed = response.my_following_status;
        },
        (code, msg) => {
          showToast(msg);
        },
        false
      );
    },
    onTab: function (index) {
      this.photoList = [];
      this.loadingEnd = false;
      this.tabIndex = index;
      this.getList();
    },
    onCell: function (idx) {
      if (this.myInfo == null) {
        return;
      }

      this.selectMomentIdx = idx;
      this.photoInfo = this.photoList[idx];
      this.showMomentDetail = true;
    },
    onBuy: function (idx) {
      this.selectMomentIdx = idx;
      this.photoInfo = this.photoList[idx];
      this.showStoryPurchaseConfirmModal = true;
    },
    onStoryPurchaseOk: function () {
      this.showStoryPurchaseConfirmModal = false;
      if (this.myInfo.gift_item2_count < this.photoInfo.price) {
        this.$root.showConfirmDlg(
          this.$t('story_purchase.item_not_enough'),
          this.$t('story_purchase.purchase_confirm'),
          this.$t('common.ok'),
          this.$t('common.cancel'),
          () => {
            this.$router.push('/gift-items');
          }
        );
      } else {
        let _app = this;

        requestPost(
          'zzalbe/purchaseStoryItem',
          {
            uid: this.myInfo.uid,
            zzalbe_id: this.photoInfo.zzalbe_id,
          },
          response => {
            _app.myInfo.gift_item1_count = response.gift_item1_count;
            _app.myInfo.gift_item2_count = response.gift_item2_count;
            _app.myInfo.gift_item3_count = response.gift_item3_count;
            _app.myInfo.gift_item4_count = response.gift_item4_count;
            _app.myInfo.gift_item5_count = response.gift_item5_count;
            _app.$store.dispatch('setMyInfo', _app.myInfo);
            
            showToast(this.$t('story_purchase.purchase_success'));

            _app.initList();
          },
          (code, msg) => {
            showToast(msg);
          },
          false
        );
      }
    },
  },
}
</script>
